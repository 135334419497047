import React from 'react'
import {Badge, Button, Col, Tab, Tabs, Table} from 'react-bootstrap'
import ReactTooltip from 'react-tooltip'
import { PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip } from 'recharts'
import ReactToPrint from 'react-to-print'
import { CSVLink } from "react-csv"

import ExportPDF  from './ExportPDF'
import ExportPDFModal  from './ExportPDFModal'

import areas from './initStructure/SpartaAreas'
import topcs from './initStructure/SpartaTopics'
import comps from './initStructure/NiceCompetencies'
import roles from './initStructure/NistWorkRoles'
import ecsfProfiles from './initStructure/ecsfProfiles'
import ecsfSkills from './initStructure/ecsfSkills'
import ecsfKnowledge from './initStructure/ecsfKnowledge'


class Statistics extends React.Component {

  render(){

    const round = (num, precision) => Number(Math.round(num + "e+" + precision) + "e-" + precision)

    const courses = () => {
      let tmp = []
      this.props.columns['1-w'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '1-w'}})))
      })
      this.props.columns['1-s'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '1-s'}})))
      })
      this.props.columns['2-w'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '2-w'}})))
      })
      this.props.columns['2-s'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '2-s'}})))
      })
      this.props.columns['3-w'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '3-w'}})))
      })
      this.props.columns['3-s'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '3-s'}})))
      })
      this.props.columns['4-w'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '4-w'}})))
      })
      this.props.columns['4-s'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '4-s'}})))
      })
      this.props.columns['5-w'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '5-w'}})))
      })
      this.props.columns['5-s'].courseIds.forEach(courseId => {
        tmp.push(JSON.parse(JSON.stringify({...this.props.courses[courseId], ...{position: '5-s'}})))
      })

      return tmp
    }

    const ectsSum = () => {
      let ects = {'1-w': 0, '1-s': 0, '2-w': 0, '2-s': 0, '3-w': 0, '3-s': 0, '4-w': 0, '4-s': 0, '5-w': 0, '5-s': 0, 'sum': 0}
      courses().forEach((course, index) => {
        ects[course.position] += course.credits
        ects['sum'] += course.credits
      })
      return ects
    }

    // NIST NICE framework

    const compIds = () => {
      let tmp = []
      courses().forEach(course => {
        course.topics.forEach(topic => {
          tmp = tmp.concat(topcs[topic.topicId].comps)
        })
      })
      return [...new Set(tmp)].sort((a,b) => {return a - b})
    }

    const ectsTopics = () => {
      let tmp = JSON.parse(JSON.stringify(topcs))
      courses().forEach(course => {
        course.topics.forEach(topic => {
          tmp[topic.topicId].credits = tmp[topic.topicId].credits ? tmp[topic.topicId].credits + (topic.ectsPer * course.credits) : (topic.ectsPer * course.credits)
        })
      })
      return tmp
    }

    const ectsAreas = () => {
      let tmp = JSON.parse(JSON.stringify(areas))
      ectsTopics().forEach(topic => {
        if (topic.credits) {
          tmp[topic.areaId].credits = tmp[topic.areaId].credits ? tmp[topic.areaId].credits + topic.credits : topic.credits
        }
      })
      return tmp
    }

    const ectsAreasSeries = ectsAreas().map(area => {return area.hasOwnProperty('credits') ? round((area.credits*100)/ectsSum()['sum'],1) : 0})

    const chartData = [
      { name: ectsAreas()[0].name, value: ectsAreasSeries[0] },
      { name: ectsAreas()[1].name, value: ectsAreasSeries[1] },
      { name: ectsAreas()[2].name, value: ectsAreasSeries[2] },
      { name: ectsAreas()[3].name, value: ectsAreasSeries[3] },
      { name: ectsAreas()[4].name, value: ectsAreasSeries[4] },
      { name: ectsAreas()[5].name, value: ectsAreasSeries[5] },
      { name: ectsAreas()[6].name, value: ectsAreasSeries[6] }
    ]

    const chartColors = ectsAreas().map(area => {return area.color})

    const chartTooltip = ({ active, payload, label }) => {
        if (active) {
            return (<div className="chart-tooltip">{payload[0].name}: <b>{payload[0].value}%</b></div>)
        }
        return null
    }

    const chartLabel = ({ name, cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
      if (percent === 0) return ""
      const RADIAN = Math.PI / 180
      const radius = innerRadius + (outerRadius - innerRadius) * 0.75
      const x = cx + radius * Math.cos(-midAngle * RADIAN)
      const y = cy + radius * Math.sin(-midAngle * RADIAN)
      return (
        <text x={x} y={y} fill="white" textAnchor={'middle'} dominantBaseline="central">
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      )
    }

    const supportedRoles = () => {
      return roles
      .sort((a,b) => {
          const bandA = a.role.toUpperCase()
          const bandB = b.role.toUpperCase()
          let comparison = 0
          if (bandA > bandB) {
            comparison = 1
          } else if (bandA < bandB) {
            comparison = -1
          }
          return comparison
        })
      .filter((role, index) => {
        let ok = true
        role.comp.every((comp) => {
          if (!compIds().includes(comp)) {
            ok = false
            return false
          } else {
            return true
          }
        })
        return ok
      })
    }

    // ECSF framework

    const ecsfSkillSum = () => {
      let tmp = []
      courses().forEach(course => {
        course.ecsfSkillIds.forEach(skill => {
          let index = tmp.findIndex(e => e.skillId === skill.skillId)
          if (index === -1) {
            tmp.push({skillId: skill.skillId, ectsSum: skill.ectsPer*course.credits})
          }
          else {tmp[index].ectsSum += skill.ectsPer*course.credits}
        })
      })
      return tmp
    }

    const ecsfKnowledgeSum = () => {
      let tmp = []
      courses().forEach(course => {
        course.ecsfKnowledgeIds.forEach(knowledge => {
          let index = tmp.findIndex(e => e.knowledgeId === knowledge.knowledgeId)
          if (index === -1) {
            tmp.push({knowledgeId: knowledge.knowledgeId, ectsSum: knowledge.ectsPer*course.credits})
          }
          else {tmp[index].ectsSum += knowledge.ectsPer*course.credits}
        })
      })
      return tmp
    }

    const supportedECSFProfiles = () => {
      return ecsfProfiles
      .map((profile, index) => {
        let ok = true
        let profileEctsSum = 0
        profile.skills.forEach(profileSkillId => {
          let skill = ecsfSkillSum().find(e => e.skillId === profileSkillId)
          if (skill === undefined) {
            ok = false
            return false
          } else {
            profileEctsSum += skill.ectsSum
            return true
          }
        })
        profile.knowledges.forEach(profileKnowledgeId => {
          let knowledge = ecsfKnowledgeSum().find(e => e.knowledgeId === profileKnowledgeId)
          if (knowledge === undefined) {
            ok = false
            return false
          } else {
            profileEctsSum += knowledge.ectsSum
            return true
          }
        })
        return ok === false ? null : {...profile, credits: profileEctsSum}
      })
      .filter(e => e !== null)
      .sort((a,b) => b.credits - a.credits)
    }

    const unSupportedECSFProfiles = () => {
      let supported = supportedECSFProfiles()
      return ecsfProfiles
      .filter(dbProfile => {
        if (undefined === supported.find(e => e.profile === dbProfile.profile)) return true
        else return false
      })
    }

    const skillsCSVexport = () => {
      let output = []
      let cours = courses().sort((a,b) => a.name.localeCompare(b.name))
      output.push(["Course/Supported Skills", ...cours.map(e => e.name)])
      ecsfSkills.forEach((skill, index) => {
        if (skill !== "") {
          output.push([skill, ...cours.map(c =>
            c.ecsfSkillIds.find(e => e.skillId === index) ? "X" : ""
          )])
        }
      })
      return output
    }

    const knowledgeCSVexport = () => {
      let output = []
      let cours = courses().sort((a,b) => a.name.localeCompare(b.name))
      output.push(["Course/Supported Knowledge", ...cours.map(e => e.name)])
      ecsfKnowledge.forEach((knowledge, index) => {
        if (knowledge !== "") {
          output.push([knowledge, ...cours.map(c =>
            c.ecsfKnowledgeIds.find(e => e.knowledgeId === index) ? "X" : ""
          )])
        }
      })
      return output
    }

    return (
      <Col className="statistics" lg ="12" xl="4">
        <h3 className="section-title"> Statistics</h3>
        <div className="content">

          {courses().length > 0 ?
          <div className="content-scroll">

            <div className="buttons-container">

              <ReactToPrint
                trigger={() => <Button variant="outline-danger">Save to PDF</Button>}
                content={() => this.componentRef}
              />
              <div style={{ display: "none" }}>
                <ExportPDF ref={el => (this.componentRef = el)} semestersToShow={this.props.semestersToShow} 
                courses={courses()} ecsfSkillSum={ecsfSkillSum()} ectsAreas={ectsAreas()} ecsfKnowledgeSum={ecsfKnowledgeSum()} supportedECSFProfiles={supportedECSFProfiles()} unSupportedECSFProfiles={unSupportedECSFProfiles()}/>
              </div>


              <ExportPDFModal semestersToShow={this.props.semestersToShow}
               courses={courses()} ecsfSkillSum={ecsfSkillSum()} ectsAreas={ectsAreas()} ecsfKnowledgeSum={ecsfKnowledgeSum()} supportedECSFProfiles={supportedECSFProfiles()} unSupportedECSFProfiles={unSupportedECSFProfiles()}/>


            </div>

            <h6 className="first">Total ECTS credits:</h6>

            <Table className="ects-overview" size="sm">
              <thead>
                <tr>
                  <th></th>
                  <th>winter</th>
                  <th>summer</th>
                  <th>sum</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>1st year</th>
                  <td>{ectsSum()['1-w']}</td>
                  <td>{this.props.semestersToShow >= 2 ? ectsSum()['1-s'] : "-"}</td>
                  <td>{ectsSum()['1-w'] + ectsSum()['1-s']}</td>
                </tr>
                {this.props.semestersToShow >= 3 ? (
                  <tr>
                    <th>2nd year</th>
                    <td>{ectsSum()['2-w']}</td>
                    <td>{this.props.semestersToShow >= 4 ? ectsSum()['2-s'] : "-"}</td>
                    <td>{ectsSum()['2-w'] + ectsSum()['2-s']}</td>
                  </tr>
                ) : null }
                {this.props.semestersToShow >= 5 ? (
                  <tr>
                    <th>3rd year</th>
                    <td>{ectsSum()['3-w']}</td>
                    <td>{this.props.semestersToShow >= 6 ? ectsSum()['3-s'] : "-"}</td>
                    <td>{ectsSum()['3-w'] + ectsSum()['3-s']}</td>
                  </tr>
                ) : null }
                {this.props.semestersToShow >= 7 ? (
                  <tr>
                    <th>4th year</th>
                    <td>{ectsSum()['4-w']}</td>
                    <td>{this.props.semestersToShow >= 8 ? ectsSum()['4-s'] : "-"}</td>
                    <td>{ectsSum()['4-w'] + ectsSum()['4-s']}</td>
                  </tr>
                ) : null }
                {this.props.semestersToShow >= 9 ? (
                  <tr>
                    <th>5th year</th>
                    <td>{ectsSum()['5-w']}</td>
                    <td>{this.props.semestersToShow >= 10 ? ectsSum()['5-s'] : "-"}</td>
                    <td>{ectsSum()['5-w'] + ectsSum()['5-s']}</td>
                  </tr>
                ) : null }
                <tr>
                  <th >sum</th>
                  <td></td><td></td>
                  <td ><b>{ectsSum()['sum']}</b></td>
                </tr>
              </tbody>
            </Table>

            <Tabs defaultActiveKey="nist" transition={false} className="stats-tabs">
              <Tab eventKey="nist" title="NIST NICE Framework">

                <h6>ECTS by SPARTA areas:</h6>

                {ectsAreasSeries.reduce((a, b) => a + b, 0) > 0 ? (
                  <ResponsiveContainer width="100%" height={296} className="chart-container">
                    <PieChart width={400} height={400}>
                      <Tooltip content={chartTooltip}/>
                      <Legend />
                      <Pie
                        animationBegin={0}
                        animationDuration={500}
                        animationEasing="ease"
                        data={chartData}
                        cx="50%"
                        cy="50%"
                        labelLine={false}
                        legendType="circle"
                        label={chartLabel}
                        outerRadius={100}
                        fill="#8884d8"
                        dataKey="value"
                      >
                        {chartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={chartColors[index % chartColors.length]} />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="list">None</div>
                )}

                <h6>ECTS by SPARTA topics:</h6>

                {ectsAreasSeries.reduce((a, b) => a + b, 0) > 0 ? (
                  <Table className="ects-topics" size="sm">
                    <thead>
                      <tr>
                        <th>Topic</th>
                        <th>ECTS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {ectsTopics().map((topic,index) => {
                        return topic.credits ? <tr key={index}><td>{topic.name}</td><td>{round((topic.credits*100)/ectsSum()['sum'],1) + "%"}</td></tr> : null
                      })}
                    </tbody>
                  </Table>
                ) : (
                  <div className="list">None</div>
                )}

                <h6>NICE Competencies:</h6>
                {compIds().length ? (
                  <ul className="list">
                    {compIds().map((compId, index) => <li key={index}>{comps[compId]}</li>)}
                  </ul>
                ) : (
                  <div className="list">None</div>
                )}

                <h6>Supported Work Roles according to NIST:</h6>

                {supportedRoles().length ? (
                  <Table className="ects-topics" size="sm">
                    <thead>
                      <tr>
                        <th>Work Role</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supportedRoles().map((role,index) =>
                        <tr key={index}>
                          <td>
                            <div className="profile hover" data-for={"ReactTooltip-" + index} data-tip>
                              {role.role}
                            </div>
                            <ReactTooltip className="tooltip" id={"ReactTooltip-" + index} place="top" effect="solid">
                              <h6>Required Competencies:</h6>
                              <ul>
                                {role.comp.map((item, index) => {
                                  return <li key={index}>{comps[item]}<br/></li>
                                })}
                              </ul>
                            </ReactTooltip>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <div className="list">None</div>
                )}


              </Tab>

              <Tab eventKey="ecsf" title={<>ECSF Framework <Badge variant="light" className="beta-light">Beta 22.9</Badge></>}>

                <h6>Supported ECSF Profiles:</h6>

                {supportedECSFProfiles().length ? (
                  <Table className="ects-topics" size="sm">
                    <thead>
                      <tr>
                        <th>Profile</th>
                        <th>ECTS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {supportedECSFProfiles().map((profile,index) =>
                        <tr key={index}>
                          <td>
                            <div className="profile hover" data-for={"ReactTooltip3-" + index} data-tip>
                              {profile.profile}
                            </div>
                            <ReactTooltip className="tooltip" id={"ReactTooltip3-" + index} place="top" effect="solid">
                              <h6>Required Skills:</h6>
                              <ul>
                                {profile.skills.map((item, index) => {
                                  return <li key={index}>{ecsfSkills[item]}<br/></li>
                                })}
                              </ul>
                              <h6>Required Knowledge:</h6>
                              <ul>
                                {profile.knowledges.map((item, index) => {
                                  return <li key={index}>{ecsfKnowledge[item]}<br/></li>
                                })}
                              </ul>
                            </ReactTooltip>
                          </td>
                          <td>{round(profile.credits,2)}</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <div className="list">None</div>
                )}

                <h6>Unsupported ECSF Profiles:</h6>

                {unSupportedECSFProfiles().length ? (
                  <Table className="ects-topics" size="sm">
                    <thead>
                      <tr>
                        <th>Profile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {unSupportedECSFProfiles().map((profile,index) =>
                        <tr key={index}>
                          <td>
                            <div className="profile hover" data-for={"ReactTooltip4-" + index} data-tip>
                              {profile.profile}
                            </div>
                            <ReactTooltip className="tooltip" id={"ReactTooltip4-" + index} place="top" effect="solid">
                              <h6>Missing Skills:</h6>
                              <ul>
                                {profile.skills.filter(skill => {
                                  if (undefined === ecsfSkillSum().find(e => e.skillId === skill)) {
                                    return true
                                  } else {
                                    return false
                                  }
                                }).map((item, index) =>
                                  <li key={index}>{ecsfSkills[item]}</li>
                                )}
                              </ul>
                              <h6>Missing Knowledge:</h6>
                              <ul>
                                {profile.knowledges.filter(knowledge => {
                                  if (undefined === ecsfKnowledgeSum().find(e => e.knowledgeId === knowledge)) {
                                    return true
                                  } else {
                                    return false
                                  }
                                }).map((item, index) =>
                                  <li key={index}>{ecsfKnowledge[item]}</li>
                                )}
                              </ul>
                            </ReactTooltip>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <div className="list">None</div>
                )}

                <h6>Supported ECSF Skills:</h6>

                {ecsfSkillSum().length ? (
                  <ul className="list">
                    {ecsfSkillSum().map((e, index) => <li key={index}>{ecsfSkills[e.skillId]}</li>)}
                  </ul>
                ) : (
                  <div className="list">None</div>
                )}

                <div className="button-csv-export">
                  <CSVLink filename={"supported-ecsf-skills.csv"} data={skillsCSVexport()} >
                    <Button variant="outline-danger" >Save as CSV</Button>
                  </CSVLink>
                </div>

                <h6>Supported ECSF Knowledge:</h6>

                {ecsfKnowledgeSum().length ? (
                  <ul className="list">
                    {ecsfKnowledgeSum().map((e, index) => <li key={index}>{ecsfKnowledge[e.knowledgeId]}</li>)}
                  </ul>
                ) : (
                  <div className="list">None</div>
                )}

                <div className="button-csv-export">
                  <CSVLink filename={"supported-ecsf-knowledge.csv"} data={knowledgeCSVexport()} >
                    <Button variant="outline-danger" >Save as CSV</Button>
                  </CSVLink>
                </div>

              </Tab>

            </Tabs>

            </div>
          :
            <div className="empty-data">
              <font className="header">There are no courses yet.</font><br/>Drag some course to your curricula.
            </div>
          }
        </div>

      </Col>
    )
  }
}

export default Statistics
